import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import { StyleSheet, css } from 'aphrodite'
import { withNamespaces } from 'react-i18next'
import { Grid, Section } from 'inno-components'
import Helmet from 'react-helmet'

import Classes from '../../styles/classes'

import ButtonImage from './ButtonImage'

const styles = StyleSheet.create({
  grid: {
    margin: '2em'
  }
})

class AccessoiresDivers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state
    const { images, t } = this.props
    const imgs = [
      images.image1.fixed.src,
      images.image2.fixed.src,
      images.image3.fixed.src,
      images.image4.fixed.src,
      images.image5.fixed.src,
      images.image6.fixed.src
    ]
    return (
      <React.Fragment>
        <Helmet
          title={t('head.title')}
          meta={[
            {
              name: 'description',
              content: t('head.meta-description')
            }
          ]}
        />
        <Section className={css(Classes.section)}>
          {typeof window !== 'undefined' && isOpen && (
            <Lightbox
              mainSrc={imgs[photoIndex]}
              nextSrc={imgs[(photoIndex + 1) % imgs.length]}
              prevSrc={imgs[(photoIndex + imgs.length - 1) % imgs.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() => this.setState({
                photoIndex: (photoIndex + imgs.length - 1) % imgs.length
              })
              }
              onMoveNextRequest={() => this.setState({
                photoIndex: (photoIndex + 1) % imgs.length
              })
              }
            />
          )}
          <Grid doubling columns={6} centered className={css(styles.grid)}>
            <Grid.Column>
              <ButtonImage
                image={images.image1}
                onClick={() => this.setState({ photoIndex: 0, isOpen: true })}
              />
            </Grid.Column>
            <Grid.Column>
              <ButtonImage
                image={images.image2}
                onClick={() => this.setState({ photoIndex: 1, isOpen: true })}
              />
            </Grid.Column>
            <Grid.Column>
              <ButtonImage
                image={images.image3}
                onClick={() => this.setState({ photoIndex: 2, isOpen: true })}
              />
            </Grid.Column>
            <Grid.Column>
              <ButtonImage
                image={images.image4}
                onClick={() => this.setState({ photoIndex: 3, isOpen: true })}
              />
            </Grid.Column>
            <Grid.Column>
              <ButtonImage
                image={images.image5}
                onClick={() => this.setState({ photoIndex: 4, isOpen: true })}
              />
            </Grid.Column>
            <Grid.Column>
              <ButtonImage
                image={images.image6}
                onClick={() => this.setState({ photoIndex: 5, isOpen: true })}
              />
            </Grid.Column>
          </Grid>
          <p
            className={css(Classes.paragraph)}
            dangerouslySetInnerHTML={{
              __html: t('paragraphes.0')
            }}
          />
        </Section>
      </React.Fragment>
    )
  }
}

export default withNamespaces('accessoires-divers')(AccessoiresDivers)
