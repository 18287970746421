import React from 'react'
import { compose, pure } from 'recompose'
import { graphql } from 'gatsby'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'
import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import AccessoiresDivers from '../../components/methode-raypath/AccessoiresDivers'

const AccessoiresDiversPage = ({ data, t }) => {
  const images = {
    image1: data.image1.childImageSharp,
    image2: data.image2.childImageSharp,
    image3: data.image3.childImageSharp,
    image4: data.image4.childImageSharp,
    image5: data.image5.childImageSharp,
    image6: data.image6.childImageSharp
  }

  return (
    <SecondaryLayout subtitle={t('titre')} page="ComponentMethodeRaypathAccessoiresDivers">
      <AccessoiresDivers images={images} />
    </SecondaryLayout>
  )
}

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('accessoires-divers')
)

export default echance(AccessoiresDiversPage)

export const pageQuery = graphql`
  query AccessoiresDiversImageQuery {
    image1: file(relativePath: { eq: "methode/accessoires-divers-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image2: file(relativePath: { eq: "methode/accessoires-divers-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image3: file(relativePath: { eq: "methode/accessoires-divers-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image4: file(relativePath: { eq: "methode/accessoires-divers-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image5: file(relativePath: { eq: "methode/accessoires-divers-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
    image6: file(relativePath: { eq: "methode/accessoires-divers-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
        fixed(width: 1200, height: 840) {
          ...GatsbyImageSharpFixed_withWebp
          src
        }
      }
    }
  }
`
